Kubrick.Layout = {
  init: function() {
    Kubrick._log("Layout: Configuring...");
    this.reinstateSavedScrollPosition();
    this.addHasOffsetFlags();
    this.addHasBgFlags();
    this.addWrapLinks();
    this.addParentHover();
  },

  addHasOffsetFlags: function() {
    // Get all BLOCKS that have an offset flag, and set the PRE-OFFSET class on previous BLOCK
    let $ITEMS = document.querySelectorAll("#content > .block.has-offset");
    Kubrick._log("Layout: Adding offset flags...", $ITEMS);
    for (let i = 0; i < $ITEMS.length; i++) {
      let $PREV = $ITEMS[i].previousElementSibling;
      if ($PREV) {
        $PREV.classList.add("pre-offset");
      }
    }

    // Check if the first BLOCK on the page has an offset flag, and the update header
    let $FIRST = document.querySelectorAll("#content > .block:first-of-type");
    if ($FIRST[0].classList.contains("has-offset")) {
      document.querySelector(".page-banner").classList.add("pre-offset");
    }
  },

  addHasBgFlags: function() {
    // Get all BLOCKS that have an offset flag, and set the PRE-OFFSET class on previous BLOCK
    let $ITEMS = document.querySelectorAll("#content > .block.has-bg");
    Kubrick._log("Layout: Adding BG flags...", $ITEMS);
    for (let i = 0; i < $ITEMS.length; i++) {
      let $PREV = $ITEMS[i].previousElementSibling;
      if ($PREV) {
        $PREV.classList.add("pre-bg");
      }
    }
  },

  addWrapLinks: function(target) {
    if (target) {
      var $ITEMS = [];
      if (target.classList && target.classList.contains("wraplink")) {
        $ITEMS = [target];
      } else {
        $ITEMS = target.querySelectorAll(".wraplink");
      }
    } else {
      var $ITEMS = document.querySelectorAll(
        "#content .block .wraplink:not(.wraplink-init)"
      );
    }
    Kubrick._log("Layout: Adding wraplinks...", $ITEMS);
    for (var i = 0; i < $ITEMS.length; i++) {
      $ITEMS[i].addEventListener("click", function(e) {
        let a = this.querySelector("a[href]");
        window.location.href = a.getAttribute("href");
      });
      $ITEMS[i].classList.add("wraplink-init");
    }
  },

  addParentHover: function() {
    let attr_name = "data-parent-target";
    let items = document.querySelectorAll(
      "#content .block .parenthover[" + attr_name + "]"
    );
    Kubrick._log("Layout: Adding parenthover...", items);
    function toggleParentState(target, state) {}
    for (var i = 0; i < items.length; i++) {
      let attr_target = items[i].getAttribute(attr_name);
      var parent = items[i].closest(attr_target);

      if (parent) {
        items[i].addEventListener("mouseenter", function(e) {
          toggleParentState(parent, true);
        });
        items[i].addEventListener("mouseleave", function(e) {
          toggleParentState(parent, false);
        });
      }
    }
  },

  reinstateSavedScrollPosition: function() {
    var name = window.name;
    if (name.length > 0 && name.indexOf("y") > -1) {
      var pos = Number(name.split("y")[1]);
      window.scrollTo({
        top: pos,
        behavior: "auto"
      });
      window.name = "";
      return true;
    }
    return false;
  },

  saveCurrentScrollPosition: function() {
    window.name = "y" + window.scrollY;
  }
};
