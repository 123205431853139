Kubrick.Modals = {
  init: function() {
    Kubrick._log("Modals: Ready...");
  },

  openModal: function($MODAL) {
    Kubrick._log("Modals: Opening...", $MODAL);
    document.querySelector("body").classList.add("no-scroll");
    $CLOSE = $MODAL.querySelector("button.close");
    $MODAL.setAttribute("aria-hidden", "false");
    var self = this;
    $CLOSE.addEventListener("click", function(e) {
      self.closeModal($MODAL);
    });
  },

  closeModal: function($MODAL) {
    Kubrick._log("Modals: Closing...", $MODAL);
    document.querySelector("body").classList.remove("no-scroll");
    $MODAL.setAttribute("aria-hidden", "true");
  }
};
