Kubrick.Menu = {
  _button_selector: "#header .menu-button",
  _menu_selector: "#site-menu",
  _mobile_menu_toggle_selector: "#site-menu .menu-toggle",
  _mobile_menu_sub_wrapper_selector: ".site-sub-level-wrapper",
  _mobile_menu_sub_selector: ".site-sub-level",

  CLASS_OPEN: "menu-open",
  CLASS_CLOSING: "menu-closing",
  CLASS_NO_SCROLL: "no-scroll",
  CLASS_CLOSED: "closed",

  $MENU: null,
  $BUTTON: null,
  $BODY: null,

  locked: false,

  // Initiation
  init: function() {
    // Get elements
    this.$BODY = document.querySelector("body");
    this.$MENU = document.querySelector(this._menu_selector);
    this.$BUTTON = document.querySelector(this._button_selector);

    // Set open menu event
    this.$BUTTON.addEventListener("click", this._onToggleMenu.bind(this));

    // Configure mobile menu controls
    this._setupMobileToggle();

    // Log
    Kubrick._log("Menu: Configuring...", this);
  },

  _setupMobileToggle: function() {
    let toggles = document.querySelectorAll(this._mobile_menu_toggle_selector);
    for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener("click", this._toggleSubSection.bind(this));
    }
  },

  _toggleSubSection: function(e) {
    Kubrick._log("Menu: Toggling menu section display", e);
    let section = e.target.closest(".site-section");
    let sub_wrapper = section.querySelector(
      this._mobile_menu_sub_wrapper_selector
    );
    let sub_menu = section.querySelector(this._mobile_menu_sub_selector);

    if (sub_wrapper && sub_menu) {
      var tween_dir = [0, sub_menu.clientHeight];
      var from_to = e.target.classList.contains(this.CLASS_CLOSED)
        ? tween_dir
        : tween_dir.reverse();

      e.target.classList.toggle(this.CLASS_CLOSED);
      console.log(from_to, sub_menu);
      anime({
        targets: sub_wrapper,
        height: from_to,
        duration: 1000,
        easing: Kubrick.EASING.NORMAL
      });
    }
  },

  // Toggle overall menu
  _onToggleMenu: function(e) {
    Kubrick._log("Menu: Toggling display", this.isVisible(), e);
    this.isVisible() == true ? this.hide() : this.show();
  },

  // Hide
  hide: function() {
    anime({
      targets: this.$MENU,
      // translateY: ["-100%", "0%"],
      opacity: [1, 0],
      duration: 500,
      easing: Kubrick.EASING.NORMAL,
      complete: function() {
        this.$BUTTON.classList.add(this.CLASS_CLOSED);
        this.$MENU.setAttribute("aria-hidden", "true");
        this.$BODY.classList.remove(this.CLASS_OPEN);
      }.bind(this)
    });

    // Only remove no-scroll if there
    if (Kubrick.Search.isVisible() == false) {
      this.$BODY.classList.remove(this.CLASS_NO_SCROLL);
    }
  },

  // Show
  show: function() {
    if (Kubrick.Search.isVisible() == true) {
      Kubrick.Search.hide();
    }
    this.$BODY.classList.add(this.CLASS_OPEN);
    this.$BUTTON.classList.remove(this.CLASS_CLOSED);
    this.$MENU.setAttribute("aria-hidden", "false");

    anime({
      targets: this.$MENU,
      // translateY: ["-100%", "0%"],
      opacity: [0, 1],
      duration: 500,
      easing: Kubrick.EASING.NORMAL
    });

    this.$BODY.classList.add(this.CLASS_NO_SCROLL);
  },

  isVisible: function() {
    return this.$BODY.classList.contains(this.CLASS_OPEN);
  }
};
