Kubrick.Tables = {
  _oversize_class: "oversized",
  init: function() {
    Kubrick._log("Tables: Configuring...", this);
    var tables = document.querySelectorAll("table");
    var content = document.querySelector("#content");
    var self = this;

    for (let i = 0; i < tables.length; i++) {
      var table = tables[i];

      // Check width and add oversized flag if needed
      if (table.clientWidth > content.clientWidth) {
        table.classList.add(this._oversize_class);
        window.addEventListener("resize", function() {
          if (table.clientWidth > content.clientWidth) {
            table.classList.add(self._oversize_class);
          } else {
            table.classList.remove(self._oversize_class);
          }
        });
      }

      // Get header and apply all the data from that to the body
      var ths = table.querySelectorAll("thead th");
      var trs = table.querySelectorAll("tbody tr");
      if (ths && trs) {
        for (let a = 0; a < ths.length; a++) {
          let tds = table.querySelectorAll(
            "tbody tr td:nth-of-type(" + (a + 1) + ")"
          );
          // console.log("tbody tr td:nth-of-type(" + (a + 1) + ")", tds);
          for (let b = 0; b < tds.length; b++) {
            let td = tds[b];
            let span = document.createElement("span");
            span.classList.add("td-title", "label", "xsmall");
            span.appendChild(txt);
            let txt = document.createTextNode(ths[a].textContent);
            td.prepend(span);
            // td.setAttribute("data-td-title", );
          }
        }
      }
    }
  }
};
