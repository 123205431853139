Kubrick.Search = {
  _button_selector: "#header .search-button",
  _search_selector: "#site-search",

  CLASS_OPEN: "search-open",
  CLASS_NO_SCROLL: "no-scroll",
  CLASS_CLOSED: "closed",

  $SEARCH: null,
  $BUTTON: null,
  $BODY: null,

  // Initiation
  init: function() {
    // Get elements
    this.$BODY = document.querySelector("body");
    this.$BUTTON = document.querySelector(this._button_selector);
    this.$SEARCH = document.querySelector(this._search_selector);

    // Set open search event
    this.$BUTTON.addEventListener("click", this._onToggleSearch.bind(this));

    // Log
    Kubrick._log("Search: Configuring...", this);
  },

  // Toggle overall search
  _onToggleSearch: function(e) {
    Kubrick._log("Search: Toggling display", this.isVisible(), e);
    this.isVisible() == true ? this.hide() : this.show();
  },

  // Hide
  hide: function() {
    anime({
      targets: this.$SEARCH,
      opacity: [1, 0],
      duration: 500,
      easing: Kubrick.EASING.NORMAL,
      complete: function() {
        this.$BODY.classList.remove(this.CLASS_OPEN);
        this.$BUTTON.classList.add(this.CLASS_CLOSED);
        this.$SEARCH.setAttribute("aria-hidden", "true");
      }.bind(this)
    });

    // Only remove no-scroll if there
    if (Kubrick.Menu.isVisible() == false) {
      this.$BODY.classList.remove(this.CLASS_NO_SCROLL);
    }
  },

  // Show
  show: function() {
    this.$BODY.classList.add(this.CLASS_OPEN);
    this.$BUTTON.classList.remove(this.CLASS_CLOSED);
    this.$SEARCH.setAttribute("aria-hidden", "false");
    this.$BODY.classList.add(this.CLASS_NO_SCROLL);

    anime({
      targets: this.$SEARCH,
      opacity: [0, 1],
      duration: 500,
      easing: Kubrick.EASING.NORMAL
    });

    if (Kubrick.Menu.isVisible() == true) {
      Kubrick.Menu.hide();
    }
  },

  isVisible: function() {
    return this.$BODY.classList.contains(this.CLASS_OPEN);
  }
};
