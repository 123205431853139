var Kubrick = {
  // Status flags
  STATUS: {
    READY: 1,
    RUNNING: 2,
    DONE: 3,
    ERROR: -1
  },

  EASING: {
    // "easeInOutQuart"
    NORMAL: "easeInOutQuad"
  },

  BREAKPOINTS: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
  },

  // Debug flag
  _debug:
    window.location.href.match(/192|localhost|dev|stg/) !== null ? true : false,

  // Internal logger during debug mode
  _log: function() {
    if (this._debug == true) {
      console.log.apply(console, arguments);
    }
  },

  _getRandomFromArray: function(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  },

  betweenBreakpoints: function(smallest, largest) {
    var bottom = Kubrick.belowBreakPoint(smallest);
    var top = Kubrick.belowBreakPoint(largest);
    if (bottom && top) {
      return true;
    }
    return false;
  },

  belowBreakPoint: function(flag) {
    let size = this.BREAKPOINTS[flag];
    if (size && window.innerWidth <= size) {
      return true;
    }
    return false;
  },

  aboveBreakPoint: function(flag) {
    let size = this.BREAKPOINTS[flag];
    if (size && window.innerWidth > size) {
      return true;
    }
    return false;
  },

  // Enapsulate top-level function calls to capture any errors
  run: function(func, obj, options) {
    try {
      if (!func) {
        throw "--- Kubrick: [run] Provided argument is not a function";
      } else {
        if (obj) {
          options ? func.call(obj, options) : func.call(obj);
        } else {
          options ? func(options) : func();
        }
      }
    } catch (e) {
      console.log("--- Kubrick: [run] Error ", e, func, obj);
    }
  },

  // Internal call for init() handlers on Module
  initiate: function() {
    Kubrick._log("--- Kubrick: [initiate] ");
    for (var key in this) {
      // Does it have an init function
      if (
        key[0] !== "_" &&
        typeof this[key] == "object" &&
        this[key].init &&
        typeof this[key].init == "function"
      ) {
        // Run method
        this.run(this[key].init, this[key]);
      }
    }
  },

  require: function require(bundleId, bundleDependencies, callbackFn) {
    if (!loadjs) {
      Kubrick._log("--- Kubrick: [require] LoadJS not loaded yet");
      return;
    }
    // Bundle not loaded, so load item
    if (!loadjs.isDefined(bundleId)) {
      Kubrick._log(
        "--- Kubrick: Require [" +
          bundleId +
          ":" +
          bundleDependencies.join(", ") +
          "] ---"
      );
      loadjs(bundleDependencies, bundleId);
    }
    loadjs.ready(["core", bundleId], {
      success: function() {
        Kubrick._log("--- Kubrick: [require] Success ", bundleId);
        try {
          callbackFn();
        } catch (e) {
          Kubrick._log("--- Kubrick: [require] Execution error ", e);
        }
      },
      error: function(pathsNotFound) {
        Kubrick._log("--- Kubrick: [require] Loading error ", pathsNotFound);
      },
      before: function(path, scriptEl) {
        //
      }
    });
  }
};
Kubrick._log("--- Kubrick: [core] Ready ---");
