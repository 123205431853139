Kubrick.Buttons = {
  _toggleButtonState: function(el, selector) {
    Kubrick._log("Buttons: Toggling", el);
    el.classList.add("show");
  },

  configureShare: function() {
    let $BLOCKS = document.querySelectorAll(".socialshare");
    let $BUTTONS = document.querySelectorAll(
      ".socialshare .facebook, .socialshare .linkedin, .socialshare .twitter"
    );
    for (let i = 0; i < $BUTTONS.length; i++) {
      $BUTTONS[i].addEventListener("click", function(e) {
        e.stopPropagation();
        window.open(
          this.href,
          this.classList[0],
          "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=750,height=500"
        );
        return false;
      });
    }
  }
};
